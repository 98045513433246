<template>
  <div class="castNav">
    <!-- <p class="castName">
      {{ `Reproduciendo en `}} <strong class="deviceFriendlyName">{{` ${deviceName}`}} </strong>
    </p> -->
    <div class="cast-metadata castName" v-if="title !== ''">
      <div class="title-metadata">
        {{ title }}
      </div>
      <div class="time-metadata">
        {{ subtitle }}
      </div>
    </div>
    <a>
      <google-cast-launcher
        v-if="isCastAvailable()"
        id="castbutton"
        ref="googleCastLauncher"
        @click="setCastOutPlayer()"
        clickable="true"
      ></google-cast-launcher>
    </a>
  </div>
</template>
<script>
import * as storeCast from "@/observables/cast";

export default {
  name: "CastIndicator",

  data() {
    return {
      deviceName: "",
      title: "",
      subtitle: "",
    };
  },
  props: {
    friendlyName: {
      type: String,
      default: "",
      required: false,
    },
  },
  mounted() {
    this.$bus.$on("set-cast-title", (title) => {
      this.title = title;
    });
    this.$bus.$on("set-cast-subtitle", (subtitle) => {
      this.subtitle = subtitle;
    });
    if (document.querySelector(".castName"))
      document.querySelector(".castName").style.display = "flex";
  },
  methods: {
    isCastAvailable() {
      return storeCast.haveSupport();
    },
    setCastOutPlayer() {
      tplay.sendGrafanaEvent(
        tplay.grafana.event.BUTTON,
        { name: "Cast", screen: this.$route.name },
        tplay.grafana.actions.ENTER
      );

      tplay.ccast.fromPlayer = false;
    },
  },
  beforeDestroy() {
    this.$bus.$off("set-cast-title", (title) => {
      this.title = title;
    });
    this.$bus.$off("set-cast-subtitle", (subtitle) => {
      this.subtitle = subtitle;
    });
  },
};
</script>
